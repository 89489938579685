import "./Nav.scss";
import React, { useEffect, useState } from "react";
import logo from "../../assets/logo/DarkThemeLogoLandscape-nobg.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { } from "@fortawesome/free-solid-svg-icons";

type SectionRef = React.MutableRefObject<HTMLDivElement | null>;
type Section = "home" | "about" | "services" | "contact";

interface Sections {
    home: SectionRef;
    about: SectionRef;
    services: SectionRef;
    contact: SectionRef;
}

interface NavProps {
    sectionsRef: Sections;
}

type sectionRefTuple = [Section, SectionRef];

function Nav({ sectionsRef }: NavProps) {
    const [backgroundOpacity, setBackgroundOpacity] = useState<number>(0);
    const [activeSection, setActiveSection] = useState<Section>("home");
    const [isVisible, setIsVisible] = useState<boolean>(true);
    // const [isNavActive, setIsNavActive] = useState<boolean>(true);

    // TODO: Figure out a way to adjust the scroll effect by applying a debounce effect which will make the scroll effect smoother and more efficient

    // The purpose of this useEffect is to track the currently section the user is on and
    // update the activeSection state accordingly
    useEffect(() => {
        const handleScroll = () => {
            // This adjustment is to make code more readable, and the casting is done to avoid
            // unexpected returned values from Object.entries with I don't know why it happens :)
            for (const [section, ref] of Object.entries(
                sectionsRef
            ) as sectionRefTuple[]) {
                if (isInView(ref.current) && activeSection !== section) {
                    setActiveSection(section);
                    break;
                }
            }
        };

        const isInView = (ref: HTMLDivElement | null) => {
            if (!ref) return false;

            const rect = ref.getBoundingClientRect();

            const windowHeight = window.innerHeight;

            return (
                rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2
            );
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [activeSection, sectionsRef]); // without this the activeSection was not being updated when the user scrolled it always stayed on home

    // I think this could be done in a better way, but I don't know how
    // The purpose of this useEffect is to change the background opacity of the navbar when the user scrolls
    // to me the problem in this that it keep calculating the opacity even when the position of the user is far away from the top of the page
    useEffect(() => {
        let lastScrollTop = window.scrollY;

        const handleScroll = () => {
            const SCROLL_THRESHOLD = 100;
            const userScroll = window.scrollY;
            const opacity = Math.min(userScroll / SCROLL_THRESHOLD, 1);

            if (opacity !== backgroundOpacity) setBackgroundOpacity(opacity);

            let currentScrollTop =
                window.scrollY || document.documentElement.scrollTop;

            if (
                currentScrollTop > SCROLL_THRESHOLD + 50 &&
                currentScrollTop > lastScrollTop
            ) {
                setIsVisible(false);
            } else if (currentScrollTop < lastScrollTop) {
                setIsVisible(true);
            }

            lastScrollTop = currentScrollTop;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [backgroundOpacity]);

    // const handleNavClick = (): void => {};

    return (
        <header
            style={{
                backgroundColor: `rgba(0, 0, 0, ${backgroundOpacity})`,
            }}
            className={`main-header ${isVisible ? "show" : "hide"}`}
        >
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="bineast logo" />
                </div>
                <nav>
                    <ul className="nav-links">
                        <li>
                            <a
                                className={
                                    activeSection === "home"
                                        ? "active"
                                        : undefined
                                }
                                href="#home"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                className={
                                    activeSection === "about"
                                        ? "active"
                                        : undefined
                                }
                                href="#about"
                            >
                                About Us
                            </a>
                        </li>
                        <li>
                            <a
                                className={
                                    activeSection === "services"
                                        ? "active"
                                        : undefined
                                }
                                href="#services"
                            >
                                Services
                            </a>
                        </li>
                        <li>
                            <a
                                className={
                                    activeSection === "contact"
                                        ? "active"
                                        : undefined
                                }
                                href="#contact"
                            >
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a href="http://learn.bineast.tech">Academy</a>
                        </li>
                    </ul>
                </nav>
                {/* <div
                    className={`control ${isNavActive ? "active" : undefined}`}
                    onClick={handleNavClick}
                >
                    <FontAwesomeIcon icon={faHamburger} />
                </div> */}
            </div>
        </header>
    );
}

export default Nav;
