import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TestimonialCard from "../../components/testimonial/Testimonial";
import "./Contact.scss";
import { useEffect, useRef, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

interface ContactProps {
    sectionRef: React.RefObject<HTMLDivElement>;
}

interface Testimonial {
    id: number;
    name: string;
    title: string;
    img: string;
    icon: IconProp;
    description: string;
}

const testimonials: Testimonial[] = [
    {
        id: 1,
        name: "Tom Durden",
        title: "Senior Developer",
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        icon: faTwitter,
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat delectus, nisi veniam incidunt rem non praesentium enim quos doloremque. Dolorum provident voluptate cupiditate amet labore consequuntur quam. Nobis blanditiis saepe repudiandae ullam porro, itaque doloribus quis similique amet architecto reiciendis?",
    },
    {
        id: 2,
        name: "Hello World",
        title: "Senior Developer",
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        icon: faTwitter,
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat delectus, nisi veniam incidunt rem non praesentium enim quos doloremque. Dolorum provident voluptate cupiditate amet labore consequuntur quam. Nobis blanditiis saepe repudiandae ullam porro, itaque doloribus quis similique amet architecto reiciendis?",
    },
    {
        id: 3,
        name: "Hi folks",
        title: "Senior Developer",
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        icon: faTwitter,
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat delectus, nisi veniam incidunt rem non praesentium enim quos doloremque. Dolorum provident voluptate cupiditate amet labore consequuntur quam. Nobis blanditiis saepe repudiandae ullam porro, itaque doloribus quis similique amet architecto reiciendis?",
    },
];

function Contact({ sectionRef }: ContactProps) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef<HTMLDivElement | null>(null);

    const handlePrevClick = () => {
        setCurrentSlide(
            (prev) => (prev - 1 + testimonials.length) % testimonials.length
        );
    };
    const handleNextClick = () => {
        setCurrentSlide((prev) => (prev + 1) % testimonials.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % testimonials.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [currentSlide]);

    useEffect(() => {
        if (sliderRef) {
            const card = sliderRef.current
                ? (sliderRef.current.children[0] as HTMLDivElement)
                : null;
            const cardWidth = card?.offsetWidth;

            if (cardWidth)
                sliderRef.current!.style.transform = `translateX(-${
                    cardWidth * currentSlide
                }px)`;
        }
    }, [currentSlide]);

    return (
        <div ref={sectionRef} id="contact" className="section">
            <div className="contact-form">
                <form>
                    <input type="text" placeholder="Enter your name" />
                    <input type="email" placeholder="Enter your Email" />
                    <textarea rows={10} placeholder="Write your message" />
                    <input type="submit" value="send" />
                </form>
            </div>
            <div className="testimonial">
                <div
                    ref={sliderRef}
                    className="slider"
                    style={{
                        width: testimonials.length * 100 + "%",
                    }}
                >
                    {testimonials.map(
                        ({ id, name, title, img, icon, description }, i) => (
                            <TestimonialCard
                                key={i}
                                name={name}
                                title={title}
                                img={img}
                                icon={icon}
                                description={description}
                                count={testimonials.length}
                            />
                        )
                    )}
                </div>
                <div className="control">
                    <div className="icon" onClick={handlePrevClick}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="icon" onClick={handleNextClick}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
