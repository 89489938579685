import "./Hero.scss";

interface HeroProps {
    sectionRef: React.RefObject<HTMLDivElement>;
}

function Hero({ sectionRef }: HeroProps) {
    return (
        <div ref={sectionRef} id="home" className="hero-section">
            <div className="container">
                <div className="hero-content">
                    <div className="hero-wrapper">
                        <h1 className="hero-title">
                            Where Ideas <br />
                            Transform into <br />
                            <span className="color-main">Software</span>
                        </h1>
                        <a className="hero-btn" href="#services">
                            Get Started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
