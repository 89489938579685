import "./About.scss";

interface AboutProps {
    sectionRef: React.RefObject<HTMLDivElement>;
}

function About({ sectionRef }: AboutProps) {
    return (
        <div ref={sectionRef} id="about" className="">
            <div className="about-wrapper">
                <div className="about-content">
                    <h1 className="about-title title">About Bineast</h1>
                    <p className="about-text">
                        Founded in 2022, Bineast is dedicated to transforming
                        visionary ideas into cutting-edge software solutions.
                        With a primary focus on developing web applications, we
                        are committed to pushing the boundaries of innovation.
                        Our journey doesn't stop there — we have ambitious plans
                        to incorporate Artificial Intelligence (AI) and Machine
                        Learning (ML) into our repertoire, offering advanced and
                        intelligent solutions. As we evolve, Bineast is poised
                        to expand into the realm of mobile applications,
                        ensuring that our technological expertise knows no
                        bounds. Join us on this exciting journey as we pave the
                        way for the future of software development.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
