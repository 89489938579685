import "./Services.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import typescriptLogo from "../../assets/technologies/typescript.png";
import reactLogo from "../../assets/technologies/react.png";
import sassLogo from "../../assets/technologies/sass.png";
import nodejsLogo from "../../assets/technologies/nodejs.png";
import modelPng from "../../assets/png/model2.png";

interface ServicesProps {
    sectionRef: React.RefObject<HTMLDivElement>;
}

function Services({ sectionRef }: ServicesProps) {
    return (
        <div ref={sectionRef} id="services">
            <div className="service section">
                <div className="service-content">
                    <h2 className="title">Web Design / Development</h2>
                    <p className="service-description">
                        In the ever-evolving digital landscape, a compelling
                        online presence is paramount. Our web design and
                        development services are meticulously crafted to elevate
                        your brand, engage your audience, and drive tangible
                        results.
                    </p>
                    <div className="details-wrapper">
                        <ul>
                            <li>
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faCheck}
                                />
                                <span>Customized Design Solutions</span>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faCheck}
                                />
                                <span>Responsive Development</span>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faCheck}
                                />
                                <span>Scalable Architecture</span>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faCheck}
                                />
                                <span>Performance Optimization</span>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faCheck}
                                />
                                <span>Security Assurance</span>
                            </li>
                        </ul>
                        <div className="stack-images">
                            <img src={typescriptLogo} alt="typescript" />
                            <img src={reactLogo} alt="react" />
                            <img src={nodejsLogo} alt="nodejs" />
                            <img src={sassLogo} alt="sass" />
                        </div>
                    </div>
                    <a href="#contact">Get in touch</a>
                </div>
                <div className="service-image">
                    <img src={modelPng} alt="model" />
                </div>
            </div>
        </div>
    );
}

export default Services;
