import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Testimonial.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TestimonialCardProps = {
    name: string;
    title: string;
    img: string;
    icon: IconProp;
    description: string;
    count: number;
};

function TestimonialCard({
    name,
    title,
    img,
    icon,
    description,
    count,
}: TestimonialCardProps) {
    return (
        <div
            className="testimonial-card"
            style={{
                width: 100 / count + "%",
            }}
        >
            <div className="image">
                <img src={img} alt={name} />
            </div>
            <div className="details">
                <span className="job-title">{title}</span>
                <span className="name">{name}</span>
            </div>
            <div className="description">{description}</div>
            <span className="icon">
                <FontAwesomeIcon icon={icon} />
            </span>
        </div>
    );
}

export default TestimonialCard;
