import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.scss";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer() {
    return (
        <div className="footer">
            <div className="accounts">
                <div>
                    <FontAwesomeIcon icon={faInstagram} />
                    <a href="./">@bineast.co</a>
                </div>
                <div>
                    <FontAwesomeIcon icon={faLinkedin} />
                    <a href="./">@bineast</a>
                </div>
            </div>
            <div className="copyright">
                <span className="copyright-symbol">&copy;</span> All rights
                reserved for Bineast
            </div>
        </div>
    );
}

export default Footer;
