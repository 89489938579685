import "./App.scss";
import { useRef } from "react";
import Nav from "./components/nav/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Hero from "./pages/hero/Hero";
import Services from "./pages/services/Services";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Footer from "./components/footer/Footer";

const Home = () => {
    const homeRef = useRef<HTMLDivElement | null>(null);
    const aboutRef = useRef<HTMLDivElement | null>(null);
    const servicesRef = useRef<HTMLDivElement | null>(null);
    const contactRef = useRef<HTMLDivElement | null>(null);

    const sectionsRef = {
        home: homeRef,
        about: aboutRef,
        services: servicesRef,
        contact: contactRef,
    };

    return (
        <>
            <Nav sectionsRef={sectionsRef} />
            <Hero sectionRef={homeRef} />
            <About sectionRef={aboutRef} />
            <Services sectionRef={servicesRef} />
            <Contact sectionRef={contactRef} />
            <Footer />
        </>
    );
};

function App() {
    return (
        <div className="main-div">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
